<template>
  <section class="section" >
     <slot></slot>
  </section>
</template>

<script>

export default {

}

</script>

<style lang="scss" scoped>
  .section {
    padding: 50px 0px;
  }

  @media only screen and (min-width: $breakpoint-med) {
    .section {
      padding: 90px 20px;
    }
  }
</style>
