<template>
  <div class="popup">
    <div class="popup-inner">
      <slot />
      <div class="popup-controls">
        <button class="popup-close" @click="TogglePopup()">
          <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="17.4536"
              y1="0.40918"
              x2="17.4536"
              y2="31.4092"
              stroke="white"
              stroke-width="2"
            />
            <line
              x1="17.4536"
              y1="0.40918"
              x2="17.4536"
              y2="31.4092"
              stroke="white"
              stroke-width="2"
            />
            <line
              x1="32.4536"
              y1="16.9092"
              x2="0.453613"
              y2="16.9092"
              stroke="white"
              stroke-width="2"
            />
            <line
              x1="32.4536"
              y1="16.9092"
              x2="0.453613"
              y2="16.9092"
              stroke="white"
              stroke-width="2"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Popup",
  props: ['TogglePopup'],
};
</script>

<style lang="scss">
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;

  &-inner {
    display: flex;
    flex-direction: column;
  }

  &-controls {
    text-align: right;
    order: -1;
  }

  &-close {
    transform: rotate(45deg) translateY(-15px);
    background-color: transparent;
    outline: none;
    border: 0 solid transparent;
    cursor: pointer;
  }
}
</style>
