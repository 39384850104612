<template>
  <Section class="section-video">
    <div class="video-inner">
      <video
        :poster="imageCover"
        preload="metadata"
        playsinline
        webkit-playsinline
        muted
      >
        <source :src="videoURL" type="video/mp4" />
        Your browser does not support the video tag. Your browser does not
        support the video tag.
      </video>
      <div class="video_embed">
        <div v-html="video"></div>
      </div>
      <div class="video-control">
        <button
          @click="() => TogglePopup('buttonTrigger')"
          class="play-btn"
        >
          <svg
            width="50"
            height="51"
            viewBox="0 0 50 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="25" cy="25.1558" r="25" fill="white" />
            <path
              d="M32.9604 25.8155L21.0171 32.7109L21.0171 18.92L32.9604 25.8155Z"
              fill="#FF384B"
            />
          </svg>
        </button>
      </div>
      <Popup
        v-if="popupTriggers.buttonTrigger"
        :TogglePopup="() => TogglePopup('buttonTrigger')"
      >
        <video class="video-embed" autoplay muted>
          <source :src="videoURL" type="video/mp4" />
        </video>
      </Popup>
    </div>
  </Section>
</template>

<script>
import Section from "@/components/Section.vue";
import Popup from "./Popup.vue";
import { ref } from "vue";

export default {
  name: "typeVideo",
  props: {
    payload: {
      type: Object,
    },
  },
  components: {
    Section,
    Popup,
  },

  setup() {
    const popupTriggers = ref({
      buttonTrigger: false,
    });

    const TogglePopup = (trigger) => {
      popupTriggers.value[trigger] = !popupTriggers.value[trigger];
    };

    return {
      Popup,
      TogglePopup,
      popupTriggers,
    };
  },

  data() {
    return {
      imageCover: require("@/assets/img/thank_you.jpg"),
      videoURL:
        "https://prismic-io.s3.amazonaws.com/supporting/8cdf9816-5fb9-4acd-b4c2-9779de0dbafd_thank_you.mp4",
      video: "",
      popupTriggered: false,
    };
  },
  methods: {
    render() {},
  },
  mounted() {
    this.render();
  },
};
</script>

<style lang="scss" scoped>
.section-video {
  position: relative;
  margin: 0 auto;
  width: 317px;
  height: 415px;
  margin-bottom: 130px;
  cursor: pointer;
  outline: none;

  &:hover {
    .video-control {
      opacity: 0.7;
    }
  }

  .video {
    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      video {
        height: 100%;
        width: 100%;
      }
    }

    &-control {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      outline: none;

      button {
        background: transparent;
        border: 0 solid transparent;
        outline: none;
        cursor: pointer;
      }

      .pause-btn {
        opacity: 0;
      }
    }

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  @media screen and (min-width: 992px) {
    margin-top: 80px;
  }
}
</style>
